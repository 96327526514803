<template>
  <div :class="getTypeClass">
    <slot />
    <span
      v-if="show && message"
      class="block font-sans text-xs tracking-normal text-left font-normal leading-3 mt-1"
      v-text="message"
    />
  </div>
</template>

<script>
// A component that wraps an input, to be able to show a message under it.
// We would support: warning / danger / info
export default {
  name: 'ThInputMessage',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'warning',
      validator: function (value) {
        return ['warning', 'danger', 'info', 'default'].indexOf(value) !== -1
      }
    },
    // Css classes names to add to the parent node, mostly used with tailwind
    cssClasses: {
      type: String,
      default: ''
    },
    // Message to be shown under the input
    message: {
      type: String,
      default: undefined
    }
  },
  computed: {
    getTypeClass() {
      return this.show && this.type ? this.type + ' ' + this.cssClasses : ''
    }
  }
}
</script>

<style scoped>
.warning {
  color: var(--info-color);
}
.warning :deep(.el-input__inner) {
  color: var(--info-color) !important;
  border-color: var(--info-color);
}
.danger {
  color: var(--error-color);
}
.danger :deep(.el-input__inner) {
  color: var(--error-color) !important;
  border-color: var(--error-color);
}
.info {
  color: var(--primary-color-focus);
}
.info :deep(.el-input__inner) {
  color: var(--primary-color-focus) !important;
  border-color: var(--primary-color-focus);
}
.default {
  @apply .text-gray-500;
}
</style>
