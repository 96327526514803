<template>
  <th-page-wrapper
    class="promotion-edit"
    color="var(--font-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <promotion-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import PromotionForm from './components/form.vue'

export default {
  components: {
    PromotionForm
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'products:promotions',
        returnedPath: { name: 'pricebooks-list' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$refs.form.handleSubmit()
    },

    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>

<style scoped>
.promotion-edit :deep(.content) {
  padding: 2rem;
}

@media (max-width: 640px) {
  .promotion-edit :deep(.content) {
    padding: 0.5rem;
  }
}
</style>
