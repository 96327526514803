import safeGet from 'just-safe-get'

export default function fnGen(form) {
  const promotableId = safeGet(form, 'promotable_product.value', 1)
  const promotableQty = safeGet(form, 'promotable_product.qty', 1)

  const bundleId = form.bundle_junctions[0].value
  const bundleQty = form.bundle_junctions[0].qty

  // Can the promotion be used for more than one bundle
  const repeatablePromotion = safeGet(form, 'repeatable_promotable', false)

  const outputValue = safeGet(form, 'output.value')
  const outputProperty = safeGet(form, 'output.property')

  const code = `
  /*
    Expected input (cart):
    {
      items: [
        {
          product_id,
          qty,
          uuid,
          amount_total_gross
        }, ...
      ]
    }

    Expected output (promotableArray):
    An array with all the cart items that had a new price because they were promotable
    [
      {
        product_id,
        qty,
        uuid,
        promotable_qty, (how many of the items in the cart item had the promotion applied)     
        promoted_total_gross (how much is the value of the cart item, could be that all the items were with discount, or just partially)
      }, ...
    ]
  */
  function main(cart = {items: []}) {
    const promotableProductId = '${promotableId}'
    const bundleProductId = '${bundleId}'
    // Hoy many products are going to recieve the promotion price
    const promotableQty = ${promotableQty}
    // How many products of this type are needed to trigger the promotion
    const bundleQty = ${bundleQty}
    // Decimal places for the amount
    const decimalPlaces = 2

    // If the promotion can be applied more than one time
    const repeatablePromotion = ${repeatablePromotion}

    const allPromotable = []
    const promotableDiscounted = []
    let totalBundleQty = 0
    let totalPromotableQty = 0
  
    // get totalBundleQty and all the promotable products
    cart.items.forEach(cartItem => {
      // is Bundle
      if (cartItem.product_id === bundleProductId) {
        totalBundleQty = totalBundleQty + cartItem.qty
      }
      // is Promotable
      if (cartItem.product_id === promotableProductId) { 
        totalPromotableQty = totalPromotableQty + cartItem.qty
        // avoid changing the input
        allPromotable.push(Object.assign({}, cartItem))
      } 
    })

    // We won't have any promotable, then we exit with a empty array
    if (!allPromotable.length) {
      return promotableDiscounted
    }

    // The price of the promotable after discount
    const promotionUnitPrice = getPromotionUnitPrice(${outputValue}, '${outputProperty}', promotableQty, allPromotable[0])
  
    // How many promotion units we can generate with the available bundles
    const totalBundleUnits = Math.floor(totalBundleQty / bundleQty)
  
    // How many promotion units we can generate with the available promotions
    const totalPromotableUnits = Math.floor(totalPromotableQty / promotableQty)
  
    // Keep the check on the one that has less units available between bundle and promotable
    let unitsLeft = totalBundleUnits <= totalPromotableUnits ? totalBundleUnits : totalPromotableUnits

    // In case the product is the same for bundle and promotable, we need to calculate different the unitsLeft
    if (promotableProductId === bundleProductId) {
      unitsLeft = Math.floor(totalPromotableQty / (promotableQty + bundleQty))
    }

    // If the promotable cannot be repeated
    // Then we have just one unitsLeft or cero if we do not have at least one bundle and promotable
    if (!repeatablePromotion) {
      unitsLeft = unitsLeft >= 1 ? 1 : 0
    }

    // If we don't have unitsLeft, that means that we won't have any promotable products
    if (!unitsLeft) {
      return promotableDiscounted
    }

    // This means that there is at least one promotion, to avoid processing
    let currentPromotableUsed = 0
    for (let promotable of allPromotable) {
        const currentQty = promotable.qty
        // unitsRequired are the promotable quantities used and to be used, divided by how many promotable form a unit
        const unitsRequired = Math.ceil((currentPromotableUsed + promotable.qty) / promotableQty)
        if (unitsRequired <= unitsLeft) {
          // The cart item is fully promotable
          // Update how many units we have left of promotable or bundle depending on which is smaller
          unitsLeft = unitsLeft - Math.floor((currentPromotableUsed + currentQty) / promotableQty) 
          // The promotable that were not used yet
          currentPromotableUsed = (currentPromotableUsed + currentQty) % promotableQty
          // We set the new price in the field amount_promotable
          promotable.promoted_total_gross = round((currentQty * promotionUnitPrice), decimalPlaces)
          // Quantity that was promotable
          promotable.promotable_qty = currentQty
          delete promotable.amount_total_gross
          promotableDiscounted.push(promotable)
        } else {
          // Partial promotable and break
          if (currentPromotableUsed || unitsLeft) {
            const currentPriceUnit = promotable.amount_total_gross / currentQty
            // How many should have promotion, depending on how many we need to finish a unit
            const promotableDeficit = (unitsLeft * promotableQty) - currentPromotableUsed
            // First part of the price is the part that we have as deficit with the promotable price, and the second part is the real price of the rest of the units with the normal price
            const promotableAmount = promotableDeficit * promotionUnitPrice
            const notPromotableAmount = (currentQty - promotableDeficit) * currentPriceUnit
            promotable.promoted_total_gross = round((promotableAmount + notPromotableAmount), decimalPlaces)
            // Quantity that was promotable
            promotable.promotable_qty = promotableDeficit
            delete promotable.amount_total_gross
            promotableDiscounted.push(promotable)
            unitsLeft = 0
          }
          // Once we have a partial promotable, then we do not need to carry on checking for new promotables
          break
        }
    }
    return promotableDiscounted

    // Helper functions
    function getPromotionUnitPrice(promotableValue, promotableType, promotableQty, promotableProductPrice) {
      const productUnitPrice = promotableProductPrice.amount_total_gross / promotableProductPrice.qty
      if (promotableType === 'amount_discount') {
        // The value of promotableValue is what we deduct from the total price of all the promotableQty
        // If the price of the discount is bigger than the value of the products, we return cero
        const productBatchPrice = productUnitPrice * promotableQty
        return productBatchPrice > promotableValue ? ((productBatchPrice - promotableValue) / promotableQty) : 0
      } else if (promotableType === 'percentage_discount') {
        // The value of promotableValue is the percentage that we discount from the total price of all the promotableQty
        return productUnitPrice * (1 - promotableValue)
      } else {
        // promotableType === 'amount_total_gross'
        // The value of promotableValue is the total price of all the promotableQty
        return promotableValue / promotableQty
      }
    }

    function round(value, decimals) {
      return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }
  }`

  return code
}
