<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-row :gutter="20">
      <el-col :lg="10" :md="14">
        <!-- Bundle junctions -->
        <el-form-item
          prop="bundle_junctions"
          :label="
            $t(
              'pages.promotions.edit.form.template.simple.bundle_junctions.label'
            )
          "
        >
          <remote-search-select
            v-if="!multipleBundles"
            id="bundle_junctions"
            ref="bundleJunctions"
            v-model="form.bundle_junctions[0].value"
            placement="top-start"
            class="w-full"
            popper-append-to-body
            resource="products"
            fetch-handler="getAll"
            :computed-fields="['custom_id', 'name']"
            :modify-query="modifyQuery"
            :placeholder="
              $t(
                'pages.promotions.edit.form.template.simple.bundle_junctions.placeholder'
              )
            "
          />
          <products-select
            v-else
            id="bundle_junctions"
            ref="bundleJunctions"
            v-model="form.bundle_junctions[0].value"
            class="w-full"
            :extend-query="modifyQuery()"
          />
        </el-form-item>
        <!-- Number of bundle junctions -->
        <el-form-item
          v-if="!multipleBundles"
          :label="
            $t(
              'pages.promotions.edit.form.template.simple.promotable_product_number.label'
            )
          "
          prop="bundle_junctions_qty"
          class="lg:w-1/2 productNumber"
        >
          <el-input
            id="bundle_junctions_qty"
            v-model="form.bundle_junctions[0].qty"
            type="number"
            :min="1"
          />
        </el-form-item>
      </el-col>
      <el-col :lg="10" :md="14">
        <!-- Promotable product -->
        <el-form-item
          prop="promotable_product"
          :label="
            $t(
              'pages.promotions.edit.form.template.simple.promotable_product.label'
            )
          "
        >
          <remote-search-select
            id="promotable_product"
            ref="promotableProduct"
            v-model="form.promotable_product.value"
            class="w-full"
            popper-append-to-body
            resource="products"
            fetch-handler="getAll"
            :computed-fields="['custom_id', 'name']"
            :modify-query="modifyQuery"
            :placeholder="
              $t(
                'pages.promotions.edit.form.template.simple.promotable_product.placeholder'
              )
            "
            @resource-set="setProduct"
          />
        </el-form-item>
        <el-row :gutter="20" class="productNumber">
          <el-col :lg="10">
            <!-- Number of promotable products -->
            <el-form-item
              prop="promotable_product_qty"
              :label="
                $t(
                  'pages.promotions.edit.form.template.simple.bundle_junctions_number.label'
                )
              "
            >
              <el-input
                id="promotable_product_qty"
                v-model="form.promotable_product.qty"
                type="number"
                :min="1"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="14">
            <!-- Discount Type -->
            <el-form-item class="mb-2" prop="discountType">
              <el-select
                id="discountType"
                v-model="discountType"
                class="w-full"
                @change="handleDiscountTypeChange"
              >
                <el-option
                  v-for="(option, index) in discountOptions"
                  :key="index"
                  :value="option.value"
                  :label="option.label"
                  :disabled="option.disabled"
                />
              </el-select>
            </el-form-item>

            <!-- Amount discount -->
            <el-form-item
              v-if="discountType !== 'percentage_discount'"
              class="w-100"
              prop="discountPrice"
            >
              <input-message
                :message="maxMessage"
                type="default"
                class="w-full"
                :show="true"
              >
                <th-currency-input
                  id="discountPrice"
                  ref="discountPrice"
                  v-model="form.output.value"
                  class="w-full"
                  :lower-limit="0"
                  :upper-limit="productDefaultPrice"
                  :placeholder="
                    $t(
                      'pages.promotions.edit.form.template.simple.output.placeholder'
                    )
                  "
                />
              </input-message>
            </el-form-item>

            <!-- Percentage discount -->
            <el-form-item v-else class="w-100" prop="discountPercentage">
              <th-number-input
                id="discountPercentage"
                ref="discountPercentage"
                v-model="form.output.value"
                percent
                :precision="2"
                :upper-limit="1.0"
                :locale="$i18n.locale"
                prefix="-"
                clearable
                :placeholder="'-' + $formatNumber(0, { style: 'percent' })"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import generateFnOne from './builders/promotion_one_promotable_one_bundle'
import generateFnMultiple from './builders/promotion_one_promotable_multiple_bundle'
import InputMessage from '@/components/inputs/message'
import RemoteSearchSelect from '@/components/select/remote-search'
import ProductsSelect from '@/containers/multi-items-select/products'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'

export default {
  components: {
    RemoteSearchSelect,
    ProductsSelect,
    InputMessage
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    multipleBundles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      product: null,
      discountType: 'amount_total_gross'
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    productDefaultPrice() {
      return safeGet(this.product, 'prices.default_prices.0.amount.gross', null)
    },
    maxMessage() {
      if (!this.productDefaultPrice) return null
      return (
        this.$t('pages.vouchers.edit.form.amount_max.label') +
        ' ' +
        this.$formatCurrency(this.productDefaultPrice, this.defaultCurrency)
      )
    },
    discountOptions() {
      return [
        {
          label: this.$t(
            'pages.promotions.edit.form.template.simple.output.amount_total_gross.label'
          ),
          value: 'amount_total_gross',
          disabled: false
        },
        {
          label: this.$t(
            'pages.promotions.edit.form.template.simple.output.amount_discount.label'
          ),
          value: 'amount_discount',
          disabled: !this.productDefaultPrice // Disable when selecter product does not have a price
        },
        {
          label: this.$t(
            'pages.promotions.edit.form.template.simple.output.percentage_discount.label'
          ),
          value: 'percentage_discount',
          disabled: !this.productDefaultPrice // Disable when selecter product does not have a price
        }
      ]
    },
    rules() {
      return {
        promotable_product: [
          {
            validator: this.validatePromotableProduct,
            trigger: ['blur', 'change']
          }
        ],
        promotable_product_qty: [
          {
            validator: this.validatePromotableQtyProduct,
            trigger: ['blur', 'change']
          }
        ],
        bundle_junctions: [
          {
            validator: this.validateBundleJunctions,
            trigger: ['blur', 'change']
          }
        ],
        bundle_junctions_qty: [
          {
            validator: this.validateBundleQtyJunctions,
            trigger: ['blur', 'change']
          }
        ],
        discountPrice: [
          {
            validator: this.validateDiscountNumber,
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    modelValue(v) {
      this.discountType = v?.output?.property || 'amount_total_gross'
    }
  },
  methods: {
    setProduct(product) {
      this.product = product
      if (!this.productDefaultPrice) {
        this.discountType = 'amount_total_gross'
      }
    },
    modifyQuery(q) {
      return {
        limit: 50,
        sellable: true,
        deleted: false,
        q
      }
    },
    validatePromotableProduct(rule, value, callback) {
      this.mandatoryField(this.form.promotable_product.value, callback)
    },
    validatePromotableQtyProduct(rule, value, callback) {
      this.mandatoryField(this.form.promotable_product.qty, callback)
    },
    validateBundleJunctions(rule, value, callback) {
      this.mandatoryField(this.form.bundle_junctions[0].value, callback)
    },
    validateBundleQtyJunctions(rule, value, callback) {
      this.mandatoryField(this.form.bundle_junctions[0].qty, callback)
    },
    mandatoryField(value, callback) {
      // Required
      if (value) {
        callback()
      } else {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      }
    },
    validateDiscountNumber(rule, value, callback) {
      // Discount can not be null, but could be cero
      if (typeof this.form.output.value === 'number') {
        callback()
      } else {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      }
    },
    compile() {
      return this.isMultiple()
        ? generateFnMultiple(this.form)
        : generateFnOne(this.form)
    },
    isMultiple() {
      return Array.isArray(this.form.bundle_junctions[0].value)
    },
    handleDiscountTypeChange(v) {
      this.form.output.value = 0
      this.form.output.property = v
    },
    async validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    }
  }
}
</script>
<style scoped>
/* Fix qty inputs labels to a fixed height*/
@media only screen and (min-width: 992px) {
  .productNumber :deep(.el-form-item__label) {
    white-space: break-spaces;
    margin-bottom: 11px;
    height: 37px;
  }
}
</style>
