<template>
  <th-wrapper :title="$t('common.titles.general_info.title')">
    <el-form ref="form" :model="form" :rules="rules" class="-mb-4">
      <el-row :gutter="20">
        <el-col :md="6">
          <!-- Promotion name -->
          <el-form-item
            prop="name"
            :label="$t('pages.promotions.form.headers.name.label')"
          >
            <el-input
              id="name"
              v-model="form.name"
              :placeholder="
                $t('pages.promotions.form.placeholder.name.placeholder')
              "
            />
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <!-- Promotion location -->
          <el-form-item
            for="locations"
            :label="
              $t('pages.promotions.edit.form.properties.available_in.label')
            "
          >
            <available-in
              id="locations"
              :model-value="{
                locations: form.locations,
                branch_groups: form.branch_groups
              }"
              :resources="resources"
              :show-items-limit="2"
              :placeholder="
                $t(
                  'pages.promotions.edit.form.properties.available_in.placeholder'
                )
              "
              @update:modelValue="handleAvailableInInput"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :md="8">
          <!-- Description -->
          <el-form-item
            prop="description"
            :label="$t('pages.promotions.form.headers.description.label')"
          >
            <el-input
              id="description"
              v-model="form.description"
              type="textarea"
              :placeholder="
                $t('pages.promotions.form.placeholder.description.placeholder')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import AvailableIn from '@/components/available-in'
export default {
  components: {
    AvailableIn
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      resources: {}
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          },
          {
            max: 255,
            message: this.$t('common.forms.rules.max_length', { length: 255 })
          }
        ],
        description: [
          {
            min: 1,
            max: 1024,
            message: this.$t('common.forms.rules.max_length', { length: 1024 })
          }
        ]
      }
    }
  },

  async mounted() {
    await this.fetchResources()
  },

  methods: {
    async fetchResources() {
      const errorMessage = this.$t(
        'pages.promotions.edit.form.errors.fetch.resources.code_XXX.content'
      )
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
      } catch (err) {
        this.$logException(err, { trackError: false, message: errorMessage })
      }
    },
    handleAvailableInInput(value) {
      this.form.locations = value.locations
      this.form.branch_groups = value.branch_groups
    },
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
