<template #template>
  <section v-loading="loading">
    <div>
      <!-- General -->
      <promotion-general ref="general" v-model="promotion" />

      <!-- Validity -->
      <promotion-validity ref="validity" v-model="promotion.constraints" />

      <!-- Template -->
      <promotion-template ref="template" v-model="promotion.template" />
    </div>
  </section>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { getConstraints } from '@/utils/constraints'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import pick from 'just-pick'
import PromotionGeneral from './promotion-general'
import PromotionValidity from './promotion-validity'
import PromotionTemplate from './promotion-template'
import { mapGetters } from 'vuex'
import { useMessagesStore } from '@/store/messages'

const defaultCartMapFn = `
// The POS will call this function and apply it
// once to all cart items. This function should
// then return a new cart array with all the products and promotions applied.
// if nothing or an empty object is returned, nothing will be applied
function main(cart = {items: []}) {
  ...
}

`

function genDefault() {
  return {
    name: null,
    description: null,
    type: 'cart_map_function',
    payload: defaultCartMapFn,
    template: {
      type: 'product_property:exclusive_bundle:output_properties',
      inputs: {
        repeatable_promotable: true,
        promotable_product: {
          property: 'id',
          value: null,
          qty: 1
        },
        bundle_junctions: [
          {
            property: 'id',
            value: null,
            qty: 1
          }
        ],
        output: {
          property: 'amount_total_gross',
          value: null
        }
      }
    },
    locations: null,
    branch_groups: null,
    constraints: getConstraints()
  }
}

export default {
  components: {
    PromotionGeneral,
    PromotionValidity,
    PromotionTemplate
  },

  data() {
    return {
      loading: false,
      promotion: genDefault(),
      pristineForm: null
    }
  },

  computed: {
    ...mapGetters({
      hasGlobalDataMode: 'Config/hasGlobalDataMode'
    }),
    isNew() {
      return !this.$route.params.id
    }
  },

  async mounted() {
    if (!this.isNew) {
      await this.fetch(this.$route.params.id)
      // Fix remote-search-select
      this.loadProducts()
    }
    this.pristineForm = this.$deepClone(this.promotion)
  },

  methods: {
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.promotions.edit.form.errors.fetch.code_XXX.content'
      )
      try {
        this.loading = true
        const { data = {} } = await th.promotions().get(id)

        if (data.id) {
          this.handleItem(data)
          this.pristineForm = this.$deepClone(this.promotion)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    loadProducts() {
      //this is a hack. the remote-search-select is rendering too quickly before the product value is passed.
      //there for we trigger `fetchInitialValue` function with refs chaining.
      //TODO: should be removed once we refactor the remote-search-select.
      this.$refs.template.fetchInitialValue()
    },

    async handleSubmit() {
      let valid = true
      const warningMessage = this.$t(
        'pages.promotions.edit.form.warnings.invalid_inputs.contents'
      )
      valid &= await this.validate('general')
      valid &= await this.validate('validity')
      valid &= await this.validate('template')
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: warningMessage
        })
      }

      this.loading = true

      // Code generation of the promotion
      this.promotion.payload = this.getCodeFromTemplate()

      const promotionPayload = this.makeHandleableBody(this.promotion)

      if (this.isNew) return this.create(promotionPayload)
      this.alter(this.payload.id, promotionPayload)
    },
    async validate(formName = 'form') {
      return await this.$refs[formName].validate()
    },
    async create(payload) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.promotion.singular')
      })

      try {
        const { data = {}, errors = [] } = await th.promotions().create(payload)

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.promotion.singular')
            })
          })
          this.pristineForm = this.$deepClone(this.promotion)
          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'promotion-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'promotions-list' })
          }
        }

        if (errors.length) {
          this.$log.debug(
            'promotions-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async alter(id, payload) {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.promotion.singular')
      })
      try {
        const { data = {} } = await th.promotions().put(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.promotion.singular')
            })
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deletePromotions()
    },
    async deletePromotions() {
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.promotion.singular')
      })
      try {
        await th.promotions().delete(this.payload.id)

        this.$router.push({ name: 'promotions-list' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    makeHandleableBody(form) {
      // replace empty objects, empty arrays and empty strings with null
      return Object.keys(form).reduce((result, key) => {
        const isEmpty =
          (isEmptyObject(form[key]) ||
            isEmptyArray(form[key]) ||
            isEmptyString(form[key])) &&
          key !== 'locations' &&
          key !== 'branch_groups'
        result[key] = isEmpty ? null : form[key]
        return result
      }, {})
    },
    handleItem(item) {
      this.payload = item

      const cleanedPayload = pick(item, Object.keys(this.promotion))

      this.promotion = {
        ...this.promotion,
        ...cleanedPayload,
        constraints: getConstraints(cleanedPayload.constraints)
      }

      this.pristineForm = this.$deepClone(this.promotion)
    },
    getCodeFromTemplate() {
      return this.$refs.template.compile()
    }
  }
}
</script>
