<template>
  <th-wrapper
    :title="$t('pages.promotions.edit.form.validity.title')"
    collapsable
    collapsed-by-default
  >
    <time-constraints
      ref="constraints"
      :model-value="modelValue"
      @update:modelValue="updateConstraints"
    />
  </th-wrapper>
</template>

<script>
import TimeConstraints from '@/components/tillhub/time-constraints'

export default {
  components: {
    TimeConstraints
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  methods: {
    updateConstraints(constraints) {
      this.$emit('update:modelValue', constraints)
    },

    validate() {
      return new Promise((resolve, _) => {
        this.$refs.constraints.validate(resolve)
      })
    }
  }
}
</script>
