<template>
  <th-wrapper
    :title="$t('pages.promotions.edit.form.template.configuration.title')"
    :info="$t('pages.promotions.edit.form.template.configuration.tooltip')"
  >
    <el-form ref="form" :model="form" class="-mb-4">
      <el-row :gutter="20">
        <el-col :lg="6">
          <!-- Promotion type -->
          <el-form-item
            prop="promotion_type"
            :label="
              $t('pages.promotions.edit.form.template.configuration.type.label')
            "
          >
            <el-select
              id="promotion_type"
              v-model="form.type"
              :placeholder="
                $t(
                  'pages.promotions.edit.form.template.configuration.select.type.placeholder'
                )
              "
            >
              <el-option
                v-for="(t, index) in templateOptions"
                :key="index"
                :value="t.value"
                :label="t.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Promotion Simple Template -->
      <promotion
        v-if="
          form.type === 'product_property:exclusive_bundle:output_properties'
        "
        ref="simpleTemplate"
        :model-value="form.inputs"
        :class="promotionClass"
      />
      <promotion
        v-if="form.type === 'product_property:and_or_concat:output_properties'"
        ref="multipleTemplate"
        :model-value="form.inputs"
        :class="promotionClass"
        multiple-bundles
      />
      <el-row class="mt-2">
        <el-col :lg="6">
          <!-- Promotion repeat -->
          <el-form-item prop="promotion_repeat">
            <el-checkbox
              id="promotion_repeat"
              v-model="form.inputs.repeatable_promotable"
            >
              {{
                $t(
                  'pages.promotions.edit.form.template.configuration.repeat.checkbox'
                )
              }}
            </el-checkbox>
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import Promotion from './templates/promotion'
export default {
  components: {
    Promotion
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      templateOptions: [
        {
          label: this.$t(
            'pages.promotions.edit.form.template.configuration.select.type.simple.label'
          ),
          value: 'product_property:exclusive_bundle:output_properties'
        },
        {
          label: this.$t(
            'pages.promotions.edit.form.template.configuration.select.type.multiple.label'
          ),
          value: 'product_property:and_or_concat:output_properties'
        }
      ],
      promotionClass: 'border-b-2 border-th-lunar-gray'
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    templateRef() {
      return this.form.type ===
        'product_property:exclusive_bundle:output_properties'
        ? this.$refs.simpleTemplate
        : this.$refs.multipleTemplate
    }
  },

  methods: {
    compile() {
      return this.templateRef.compile()
    },
    fetchInitialValue() {
      this.templateRef.$refs.promotableProduct.fetchInitialValue()
      this.templateRef.$refs.bundleJunctions.fetchInitialValue()
    },
    async validate() {
      return new Promise((resolve) => {
        this.templateRef.validate(resolve)
      })
    }
  }
}
</script>
